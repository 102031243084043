import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import HomePage from './_components/HomePage'

const Index = ({ data: { props, articles } }) => {
	return <HomePage {...props} articles={articles} />
}
Index.propTypes = { data: object, pageContext: object }

export default Index

export const GatsbyQuery = graphql`
	# Write your query or mutation here
	{
		articles(limit: 6, sort: { published: DESC, modified: DESC }) {
			title
			path
			isSponsored
			template
			modified
		}
		props: pageModel(slug: "homepage") {
			slug
			hasVwo
			meta {
				robots
				description
			}
			... on HomePage {
				experts {
					company
					imageUrl
					industry
					jobTitle
					name
					path
					placement
				}
				categories {
					description
					imageUrl
					path
					categoryTag
				}
			}
		}
	}
`
